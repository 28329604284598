@import '../base/colors';

* {
  &::-webkit-scrollbar {
    border-radius: 2rem;
    height: 0.5rem;
    width: 0.5rem;
    z-index: 10;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $secondary5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary5;
    border-radius: 2rem;
  }
}
