/**
 * Basic typography style for copy text
 */
@tailwind base;

@layer base {
  p {
    @apply text-small font-partialBold;
  }

  h3 {
    @apply text-small font-extrabold;
  }

  h2 {
    @apply text-regular font-partialBold;
  }

  h1 {
    @apply text-large font-regular;
  }

  input {
    @apply text-small font-regular;
  }

  span {
    @apply text-xs font-regular tracking-wide;
  }

  h4 {
    @apply text-small font-bold;
  }

  link {
    @apply text-link font-partialBold;
  }

  li {
    @apply text-small font-regular;
  }

  text {
    @apply text-small font-regular;
  }

  data {
    @apply text-xs font-regular;
  }

  h5 {
    @apply text-disclaimer font-bold;
  }

  h6 {
    @apply text-disclaimer font-regular;
  }

  header {
    @apply text-disclaimer font-extrabold;
  }

  aside {
    @apply text-disclaimer text-textColor-disclaimer
    font-partialBold opacity-90;
  }

  ul {
    list-style-type: disc;
    padding: 10px;
  }
}
